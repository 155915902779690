@import "styles/base";

.searchWrapper {
  position: relative;
  margin-top: 20px;
  .searchButton {
    position: absolute;
    top: calc(50% - 35px);
    right: 35px;
    width: 70px;
    height: 70px;
    background: #ff7427;
    border-radius: 50% !important;
    color: rgba(5, 43, 53, 1);
    .searchIcon {
      width: 30px;
    }
  }
}

.inputSearch {
  @include font(24px, 300, 32px, $font-family-silka);
  width: 100%;
  height: 100%;
  padding: 24px 130px 24px 44px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.01);
  border-radius: 23px;
  border: none;
  outline: none;
  resize: none;
  overflow: auto;
  color: #052b35;
  min-height: 200px;

  @include media("<desktop") {
    @include font(22px, 300, 1.5, $font-family-silka);
    padding: 22px 29px 0;
    height: 180px;
  }

  @include media("<tablet") {
    @include font(20px, 300, 1.5, $font-family-silka);
    padding: 22px 29px 0;
    height: 180px;
  }
}
