@import "../../styles/base.scss";

.headerWrapper {
  display: flex;
  justify-content: space-between;
  max-width: 1100px;
  margin: 0 auto;
  padding: 35px 0;
  .logoWrapper {
    display: flex;
    align-items: center;
  }
  .buttonsWrapper {
    display: flex;
    flex-wrap: wrap;
  }
  .buttonsWrapper button {
    padding: 10px 20px;
    font-size: 12px;
  }
  @include media("<tablet") {
    flex-wrap: wrap;
    .buttonsWrapper {
      margin: 0px 20px 20px 20px;
    }
    .buttonsWrapper > div {
      margin-top: 8px;
    }
    .logoWrapper {
      flex-direction: column;
    }
  }
}
.partnerLogo {
  max-height: 32px;
}
.poweredBy {
  font-size: 12px;
  line-height: 30px;
  margin: 0 10px;
  white-space: nowrap;
}
.headerBtn {
  &:hover {
    background-color: #dfdfdf !important;
    color: #000 !important;
  }
}
.headerBtn:first-child {
  @media (max-width: 1200px) {
    display: none !important;
  }
}
