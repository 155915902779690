@import "styles/base";

.header {
  @include font(16px, normal, 22px, $font-family-sharp-grotesk);
  color: #000;
  margin: 0 0 15px 0;
  padding: 0;
}

.wrapperList.hasShadow {
  position: relative;

  &::after {
    content: "";
    background: linear-gradient(360deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 15px;
    z-index: 1;
  }

  &.variantColor {
    &::after {
      background: linear-gradient(360deg, rgba(234, 250, 254, 1) 0%, rgba(255, 255, 255, 0) 100%);
    }
  }

  .list {
    padding-bottom: 10px;
  }
}

.list {
  list-style: none;
  .down {
    display: inline-block;
    transform: translateY(2px);
  }

  .item {
    @include font(15px, 400, 1.2);
    margin-bottom: 4px;
    padding: 2px 0 2px;
    display: flex;
    flex-direction: column;
    color: #000;

    &:last-child {
      margin-bottom: 0;
    }

    label {
      cursor: pointer;
      margin-left: 20px;
      &:first-letter {
        text-transform: uppercase;
      }
    }

    .checkbox {
      padding: 0;
      margin-right: 4px;
    }

    .value {
      padding-top: 1px;
      display: inline-block;
    }

    .countOffers {
      @include font(12px, 300, 1.2);
      margin-left: 10px;
      background-color: #d4f5ff;
      border-radius: 4px;
      padding-left: 3px;
      padding-right: 3px;
      display: inline-block;

      &.count {
        background-color: #ffd443;
        font-size: 10px;
        padding: 1px 4px;
      }
      &.minPrice {
        background-color: #faf9f6;
        font-size: 10px;
        padding: 4px 4px;
      }
    }

    .list::-webkit-scrollbar {
      display: none;
    }

    .showMoreLessToggle {
      text-decoration: underline;
      cursor: pointer;
    }

    .disabledLabel {
      color: #ccc;
      cursor: default;
      .count {
        color: #000;
      }
    }
    .priceInput_Box {
      margin-top: 16px;
      margin-bottom: 24px;

      .li_priceInput {
        list-style: none;
        font-size: 15px;
        font-weight: 400;
        margin-left: 0;
      }

      .priceInput_container {
        display: flex;
        .priceInput_Label {
          margin: 0;
          margin-top: 7px;
          padding: 5px;
        }
        a {
          padding: 7px;
          color: black;
        }
        input {
          border-radius: 5px;
          border: none;
          background-color: hsl(0, 0%, 97%);
          color: black;
        }
      }
      .priceInput_Error {
        display: flex;
        color: rgb(248, 68, 68);
        justify-content: center;
      }
      .searchButton {
        background-color: #ffbb12 !important;
        width: 15px !important;
      }
    }
    .icon_button {
      padding: 0 !important;
      margin-left: 5px !important;
      min-width: 40px !important;
      max-height: 40px !important;
      background-color: #ff7427 !important;
      .searchIcon {
        width: 20px;
      }
    }
  }
}
