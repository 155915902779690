@import "styles/base";

.searchInput {
  width: 100%;
  height: 40px;
  border: 1px solid #ccc;
  text-align: left;
  border-radius: 4px;
  line-height: 1.5;
  font-weight: 400;
  margin-bottom: 16px;
  background-color: transparent;
  padding: 0 1em 0 0;
  font-family: $font-family-silka;
  line-height: 1.5;
  appearance: none;
  font-size: inherit;
  cursor: inherit;
  outline: none;
}
.searchInput::placeholder {
  padding-left: 8px;
}
