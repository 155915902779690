@import "styles/base";

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  padding-bottom: 64px;
}

.priceBox {
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  margin: 20px 10px;
  text-align: center;
  padding: 20px 10px;
  line-height: 23px;
  cursor: pointer;
  width: calc(100% - 20px) !important;
  span {
    font-size: 12px;
    font-weight: normal;
  }
}

.filters {
  font-family: $font-family-silka;
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
  flex: none;
  width: 255px;

  @include media("<desktop") {
    display: none;
  }
}

.sort {
  font-family: $font-family-silka !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.contactLink {
  color: #000;
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
  }
}
