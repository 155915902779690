@import "styles/base";
@import "../../SearchSemanticModel/SearchSemanticModel.module.scss";

.container {
  @media (max-width: 1200px) {
    display: none;
  }
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Przezroczyste tło z przyciemnieniem */
  backdrop-filter: blur(2px);
  z-index: 4999; /* Niższy niż z-index popupu, ale wyższy niż reszta zawartości */
  pointer-events: auto; /* Blokuje interakcję z zawartością pod tłem */
}
.onboarding_popup {
  background-color: #ffffff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;
  width: 50%;
  max-width: 700px;
  text-align: center;
  position: absolute;
  z-index: 5000 !important;
  top: 500px;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #000000;
  border-radius: 2%;
}
@include media("<tablet") {
  .onboarding_popup {
    width: 100%;
    top: 400px;
  }
}
.onboarding_box {
  border: 1px solid;
  border-radius: 2%;
  height: 100%;
  padding: 48px;
}
.onboarding_box_steps {
  font-family: "Silka";
  background-color: #fdd500;
  position: absolute;
  border-radius: 20px;
  padding: 15px;
  display: flex;
  align-items: center;
}
.onboarding_button_box {
  background-color: #fdd500;
  position: absolute;
  border-radius: 20px;
  padding-inline: 50px;
  display: flex;
  align-items: center;
  gap: 40px;
  button {
    font-family: "Silka";
    text-transform: capitalize !important;
    white-space: nowrap;
    padding-inline: 50px !important;
  }
}

.onboarding_popup_step2 {
  position: absolute;
  z-index: 5000 !important;

  .onboarding_box_steps {
    white-space: nowrap;
    border-top-left-radius: 0;
    left: 600px;
    bottom: 1px;
  }
  .onboarding_button_box {
    position: absolute;
    top: 100px;
    left: 200px;
  }
}

.onboarding_popup_step3 {
  z-index: 5000 !important;
  width: fit-content !important;
  position: absolute;
  .onboarding_box_steps {
    white-space: nowrap;
    padding-block: 32px;
    border-bottom-right-radius: 0;
    top: 200px;
  }
  .onboarding_button_box {
    position: absolute;
    top: 700px;
    left: 200px;
  }
}

.onboarding_popup_step4 {
  z-index: 5000 !important;
  position: absolute;
  width: 900px;
  .onboarding_box_steps {
    border-top-left-radius: 0;
    top: 1950px;
    left: 300px;
  }
  .onboarding_button_box {
    position: absolute;
    top: 2400px;
    left: 300px;
  }
}

.onboarding_popup_step5 {
  z-index: 5000 !important;
  position: absolute;
  width: 850px;
  .onboarding_box_steps {
    border-top-right-radius: 0;
    bottom: 520px;
    left: 280px;
  }
  .onboarding_button_box {
    position: absolute;
    bottom: 250px;
    left: 200px;
  }
}

.onboarding_popup_step6 {
  z-index: 5000 !important;
  position: absolute;
  width: 730px;
  .onboarding_box_steps {
    border-top-right-radius: 0;
    bottom: 520px;
    left: 100px;
  }
  .onboarding_button_box {
    position: absolute;
    bottom: 250px;
    left: 200px;
  }
}

.onboarding_box img {
  width: 40%;
  height: auto;
  margin-bottom: 10px;
}

.onboarding_popup h1 {
  font-size: 24px;
  margin-top: 0;
  margin-bottom: 10px;
}
.onboarding_popup a {
  text-decoration: underline;
  color: #000000;
}
.onboarding_popup p {
  font-size: 16px;
  color: #000000;
  margin-bottom: 20px;
}

.nextButton {
  background-color: #0b4356 !important;
  color: #ffffff !important;
  border-radius: 10px !important;
  border: 1px solid #0b4356 !important;
  padding: 10px 20px !important;
  font-size: 18px !important;
  margin: 10px !important;
  cursor: pointer !important;
  text-transform: capitalize !important;
}
.backButton {
  background-color: #ffffff !important;
  color: #0b4356 !important;
  border-radius: 10px !important;
  border: 1px solid #0b4356 !important;
  padding: 10px 20px !important;
  font-size: 18px !important;
  margin: 10px !important;
  cursor: pointer !important;
  text-transform: capitalize !important;
}
.onboarding_popup button:hover {
  background-color: #ffffff;
  color: black;
  border: 1px solid;
  border-color: #0b4356;
}
.closeButton {
  background-color: #fdd70000 !important;
  :hover {
    background-color: #fdd70000 !important;
  }
}
