@import "styles/base";
*,
*::before,
*::after {
  box-sizing: border-box;
}
.select {
  border: 1px solid #ccc;
  border-radius: 4px;
  line-height: 1.5;
  font-weight: 400;
  margin-bottom: 16px;
  appearance: none;
  // Additional resets for further consistency
  background-color: transparent;
  padding: 0 1em 0 0;
  width: 100%;
  font-family: $font-family-silka;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  outline: none;

  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px);
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.select {
  padding: 8px;
}
