@import "styles/base";

.formWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 160px;

  .textareaContainer {
    position: relative;
    .searchButton {
      position: absolute;
      top: calc(50% - 35px);
      right: 35px;
      width: 70px;
      height: 70px;
      background: #ff7427;
      border-radius: 50% !important;
      color: rgba(5, 43, 53, 1);
      .searchIcon {
        width: 30px;
      }
    }
  }
}
.classicSearch_container {
  align-self: flex-end;
  justify-content: center;
  position: relative;
  @include media("<tablet") {
    margin-top: 20px;
  }
  a {
    position: relative;
    bottom: 10px;
    margin-right: 5px;
  }
  .classicSearch_button {
    width: fit-content;
    align-self: flex-end;
    margin-bottom: 28px !important;
    text-transform: capitalize !important;
  }
}

.classicSearch_box {
  margin-top: -160px;
  margin-bottom: 80px;
}
.backgroundImage {
  align-self: flex-end;

  @include media("<tablet") {
    display: none;
  }

  img {
    max-width: 100%;
    height: auto;
    margin: 0 0 0 auto;
    @media (max-width: 850px) {
      max-height: 310px;
    }
  }
}

.header {
  font-family: $font-family-sharp-grotesk !important;

  @include media("<=desktop") {
    margin-left: 120px;
    width: 100%;
  }
}

.formSearch {
  @include font(24px, 300, 1.2, $font-family-silka);
  color: #000000;
  resize: none;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.01);
  width: 87vw;
  max-width: 1100px;
  height: 163px;
  border-radius: 23px;
  border: none;
  outline: none;
  margin-bottom: 5px;
  padding: 36px 105px 36px 36px;

  @include media("<desktop") {
    @include font(20px, 300, 1.2, $font-family-silka);
    padding: 24px 105px 36px 24px;
    max-width: 90vw;
    height: 163px;
  }

  @include media("<tablet") {
    height: 130px;
  }

  &::placeholder {
    @include font(24px, 300, 1.2, $font-family-silka);
    color: rgba(5, 43, 53, 0.5);
    white-space: pre-line;
    justify-content: center;
    padding-left: 0;
    -webkit-text-fill-color: initial;

    @include media("<desktop") {
      @include font(20px, 400, 1.2, $font-family-silka);
    }
  }
}
