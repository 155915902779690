@font-face {
  font-family: "Sharp Grotesk Book";
  src: url("../fonts/sharp-grotesk/SharpGroteskBook20.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Sharp Grotesk Medium";
  src: url("../fonts/sharp-grotesk/sharpgrotesk-medium20.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Galano Grotesque";
  src: url("../fonts/galano-grotesque/galano-grotesque-regular.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Silka";
  src: url("../fonts/silka/silka-light.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Silka";
  src: url("../fonts/silka/silka-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Silka";
  src: url("../fonts/silka/silka-medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Silka";
  src: url("../fonts/silka/silka-semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Test Tiempos Headline";
  src: url("../fonts/test-tiempos-headline/TiemposHeadline-Light.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Test Tiempos Headline";
  src: url("../fonts/test-tiempos-headline/TiemposHeadline-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Test Tiempos Headline";
  src: url("../fonts/test-tiempos-headline/TiemposHeadline-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Test Tiempos Headline";
  src: url("../fonts/test-tiempos-headline/TiemposHeadline-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
