@import "styles/base";

.searchHeader {
  @include font(18px, 600, 24px, $font-family-sharp-grotesk);
  margin: 0;
}

.onboarding_semantic {
  position: relative;
}

.filtersWrapper {
  @include font(18px, 400, 24px, $font-family-silka);
  margin: 0 -12px 10px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;

  .item {
    background: #fff;
    color: #000;
    margin: 4px;
    border-radius: 106px;
    padding: 12px 16px;
    min-width: 100px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .propertyIcon {
    margin-right: 8px;
  }
}
.showMoreLessToggle {
  text-decoration: underline;
  cursor: pointer;
  display: inline-block;
  padding: 10px 15px;
  font-weight: 400;
  font-size: 18px;
}

.semanticModelsWrapper {
  list-style: none;
  margin: 0 -12px 0;

  .item {
    @include font(18px, 400, 24px, $font-family-silka);
    border-radius: 106px;
    padding: 12px 16px;
    margin: 4px;
    display: inline-block;
    min-width: 100px;
    background-color: #0b4356;
    color: #fff;
    text-align: center;
  }
}
