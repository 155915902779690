@import "styles/base";

.header {
  @include font(16px, normal, 22px, $font-family-sharp-grotesk);
  color: #000;
  margin: 0 0 15px 0;
  padding: 0;
}

ul {
  list-style: none;
  padding: 0;
  margin: 2px 0;

  &.countryList {
    margin-left: -5px;
    li {
      margin-bottom: 5px;
      list-style: none;
    }
  }

  &.regionList {
    margin-left: 18px;
    margin-bottom: 0;
  }

  &.cityList {
    margin-left: 18px;
    margin-bottom: 5px;
  }
}

.item {
  @include font(15px, 400, 1.5);
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  color: #000;
  padding: 0;

  &:last-child {
    margin-bottom: 0;
  }

  label {
    cursor: pointer;
    margin-left: 20px;
    &:first-letter {
      text-transform: uppercase;
    }
  }

  .checkbox {
    padding: 0;
    margin-right: 4px;
  }

  .value {
    padding-top: 1px;
    display: inline-block;
  }

  .countOffers {
    @include font(12px, 300, 1.2);
    margin-left: 10px;
    background-color: #d4f5ff;
    border-radius: 4px;
    padding-left: 3px;
    padding-right: 3px;
    display: inline-block;

    &.count {
      background-color: #ffd443;
      font-size: 10px;
      padding: 1px 4px;
    }
    &.minPrice {
      background-color: #faf9f6;
      font-size: 10px;
      padding: 4px 4px;
    }
  }
}
.showMoreLessToggle {
  text-decoration: underline;
  cursor: pointer;
}

.disabledLabel {
  color: #ccc;
  cursor: default;
  .count {
    color: #000;
  }
}
