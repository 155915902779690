@import "styles/base";
@import "styles/variables";

.singleOffer {
  width: 100%;
}

.termContainer {
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: map-get($breakpoints, desktop)) {
    flex-direction: column;
  }
}
