.cookies_info {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
  font-size: 18px;
  line-height: 20px;
  z-index: 10000;
  position: fixed;
  bottom: 100px;
  background: #fff;
  color: rgba(0, 0, 0, 0.5);
  padding: 40px;
  width: 80%;
  left: 10vw;
  text-align: center;
  margin: auto;
}

.cookies_info a {
  color: #000000;
  text-decoration: underline;
}
