@import "styles/base";

.footerWrapper {
  display: flex;
  justify-content: space-between;
  padding: 48px 16px;
  position: relative;

  @include media("<tablet") {
    flex-direction: column;
    padding: 48px 16px;
  }
}

.wrapper {
  max-width: 1170px;
  margin: auto;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.footerElements {
  background-color: #0b4356;
  padding: 56px 0 56px 0;

  .header {
    @include font(13px, 300, 20px);
    margin-bottom: 40px;
    color: #85a1aa;
    text-transform: uppercase;

    @include media("<tablet") {
      margin-bottom: 10px;
      margin-top: 40px;
    }
  }

  @include media("<tablet") {
    padding-top: 0;
  }
}
.footerElementsWrapper img {
  padding-right: 20vw;
  @include media("<tablet") {
    margin-top: 36px;
  }
}
.footerCompanyName {
  margin-left: 16px;

  @include media("<tablet") {
    margin-left: 16px;
  }
}
.footerElementsWrapper {
  display: flex;
  margin: 0 auto;
  max-width: 1100px;
  justify-content: space-between;

  .column {
    padding: 0 16px;
    width: 175px;

    @include media("<tablet") {
      padding: 0;
    }
  }

  @include media("<tablet") {
    max-width: 100%;
    width: 100%;
    margin: 0 20px 0 20px;
  }

  @include media("<phone") {
    padding: 36px 0 0 16px;
    flex-direction: column;
    margin: 0;
  }
}

.footerProjectDesc {
  max-width: 350px;
  margin-right: 40px;

  @include media("<tablet") {
    margin-bottom: 20px;
  }
}

.footerLogos {
  width: 100%;
  max-width: 660px;
  text-align: center;

  @include media("<tablet") {
    max-width: none;
    margin-top: 20px;
  }

  img {
    width: 100%;
  }
}

.footerSocial {
  @include font(13px, 200, 1.5, $font-family-silka);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.5);
  letter-spacing: 1px;

  h3 {
    color: rgba(255, 255, 255, 0.5);
    font-family: $font-family-silka;
    letter-spacing: 1px;
    font-size: 13px;
    padding-left: 16px;
    font-weight: 400;

    @include media("<tablet") {
      text-align: initial;
      padding-left: 16px;
    }
  }

  img {
    color: rgba(255, 255, 255, 0.5);
    height: 20px;
    padding-left: 62px;
  }

  @include media("<tablet") {
    padding: 0px;
    justify-content: flex-start;
  }
}

a {
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
}
