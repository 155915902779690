@import "styles/base";
.ratesWrapper {
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(196, 196, 196, 0.7);
  height: 100%;
  display: flex;
  min-width: 80px;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  @include media("<tablet") {
    right: 0;
  }
  span {
    display: none;
  }
  .rate {
    cursor: pointer;
    max-width: 40px;
    max-height: 43px;
    align-items: center;
    position: relative;
    &:hover {
      max-width: 50px;
      max-height: 53px;
      span {
        position: absolute;
        top: 14px;
        right: 50px;
        display: inline-block;
        font-size: 14px;
        padding: 3px 7px;
        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0.8);
        z-index: 3;
      }
    }
  }
}

.popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: #fff;
  border: none;
  padding: 30px 50px;
  border-radius: 40px;
  text-align: center;
  &:focus {
    outline: none;
  }
}
