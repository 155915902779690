@import "styles/base";

.offerDetail {
  display: flex;
  justify-content: space-between;
  padding: 36px 5px 45px 5px;
  margin-top: 36px;
  border-top: 1px solid #dadada;
  font-family: $font-family-silka;
  font-size: 16px;
  font-weight: 400;
  @include breakpoint(medium) {
    flex-direction: column;
  }
}

.offerHeader {
  display: flex;
  padding-bottom: 8px;
}
