@import "styles/base";

.container {
  border-radius: 16px;
  margin-top: 30px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  .contactText {
    padding-inline: 30px;
    padding-top: 25px;
    padding-bottom: 15px;
    white-space: pre-wrap;
    text-align: center;
  }

  button {
    text-transform: none;
    padding-inline: 30px;
    padding-block: 3px;
    margin-bottom: 20px;
    width: fit-content;
    align-self: center;
  }
}
