.priceInput_Box {
  margin-bottom: 24px;

  .li_priceInput {
    list-style: none;
    font-size: 15px;
    font-weight: 400;
    margin-left: 0;
  }

  .priceInput_container {
    display: flex;
    .priceInput_Label {
      margin-top: 7px;
      padding: 5px;
    }
    a {
      padding: 7px;
      color: black;
    }
    input {
      border-radius: 5px;
      border: none;
      background-color: hsl(0, 0%, 97%);
      color: black;
    }
  }
  .priceInput_Error {
    display: flex;
    color: rgb(248, 68, 68);
    justify-content: center;
  }
  .searchButton {
    background-color: #ffbb12 !important;
    width: 15px !important;
  }
}
.icon_button {
  padding: 0 !important;
  margin-left: 5px !important;
  min-width: 40px !important;
  max-height: 40px !important;
  .searchIcon {
    width: 20px;
  }
}
