@import "styles/base";

.offersList {
  width: 100%;
  padding-left: 45px;
  box-sizing: border-box;
  li {
    list-style: none;
  }
  @include media("<desktop") {
    padding-left: 0;
  }
}
