@import "styles/base";

.relatedSearches {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

.relatedSearches div {
  display: flex;
  align-items: center;
  border-radius: 107px;
  background-color: #f2f3f2;
  padding: 10px 20px;
  min-width: 45%;
  margin: 5px 10px;
  cursor: pointer;

  .searchIcon {
    height: 20px;
    margin-right: 10px;
  }

  &:hover {
    background-color: #ccc;
  }

  @include media("<desktop") {
    min-width: 100%;
  }
}
