@import "styles/base";
.headerNav {
  background-color: #00b3f9;
}
.headerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto;
  padding: 15px 30px;
  .logoWrapper {
    display: flex;
    align-items: center;
    padding: 20px 0;
    .partnerLogo {
      max-height: 32px;
    }
  }
  .buttonsWrapper {
    display: flex;
    flex-direction: row;
  }
  .buttonsWrapper button {
    padding: 10px 20px;
    font-size: 12px;
  }
  @include media("<phone") {
    flex-wrap: wrap;
    .buttonsWrapper {
      margin: 40px 20px 20px 20px;
    }
    .buttonsWrapper > div {
      margin-top: 8px;
    }
    .searchBox {
      display: none;
    }
  }
}
.poweredBy {
  font-size: 12px;
  line-height: 30px;
  margin: 0 10px;
  white-space: nowrap;
}
.headerBtn {
  &:hover {
    background-color: #dfdfdf !important;
    color: #000 !important;
  }
}
.searchBox {
  position: relative;
  width: 50%;
  margin: 0 50px;
}
.inputSearch {
  @include font(20px, 300, 56px, $font-family-silka);
  width: 100%;
  height: 56px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.01);
  border-radius: 23px;
  border: none;
  outline: none;
  resize: none;
  overflow: auto;
  color: #052b35;
  padding: 0 40px 0 20px;

  box-sizing: border-box;

  @include media("<tablet") {
    font-size: 18px;
  }
}

.searchIcon {
  width: 46px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
  padding: 14px;

  &:hover {
    opacity: 0.5;
  }
}
