@import "styles/base";
.relatedOffersBox {
  display: flex;
  gap: 30px;
  padding-bottom: 48px;
  align-items: normal;
  @include media("<tablet") {
    flex-direction: column;
    gap: 0px;
    .Evaluation_rates {
      right: 0;
    }
  }
}

@media (max-width: 1160px) {
  .list button {
    margin-left: 10px;
  }
  .list button:last-child {
    margin-right: 10px;
  }
}
