@import "styles/base";

.list {
  display: flex;
  flex-direction: column;
  margin-top: -10px;
}

.item {
  @include font(10px, 300, 1.1);
  display: flex;
  align-items: center;
  // flex-direction: column;
  padding: 0;
  margin-right: 22px;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }

  .checkbox {
    margin: 0 0 0 -10px;
  }

  label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}
.countOffers {
  @include font(12px, 300, 1.2);
  margin-left: 10px;
  background-color: #d4f5ff;
  border-radius: 4px;
  padding-left: 3px;
  padding-right: 3px;
  display: inline-block;

  &.count {
    background-color: #ffd443;
    font-size: 10px;
    padding: 1px 4px;
  }
  &.minPrice {
    background-color: #faf9f6;
    font-size: 10px;
    padding: 4px 4px;
  }
}
