@import "styles/base";

.list {
  list-style: none;
  max-height: 320px;
  overflow-x: hidden;
  overflow-y: auto;

  .item {
    @include font(15px, 400, 1.2);
    margin-bottom: 0;
    padding: 2px 0 0;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    color: #000;

    .checkbox {
      padding: 0;
      margin-right: 4px;
    }

    .count {
      @include font(10px, 300, 1.2);
      margin-left: 10px;
      background-color: #ffd443;
      border-radius: 4px;
      padding-left: 3px;
      padding-right: 3px;
    }

    .stars {
      cursor: pointer;
      svg {
        width: 18px;
        padding-top: 2px;
      }
    }
  }
}

.countOffers {
  @include font(12px, 300, 1.2);
  margin-left: 10px;
  background-color: #d4f5ff;
  border-radius: 4px;
  padding-left: 3px;
  padding-right: 3px;
  display: inline-block;

  &.count {
    background-color: #ffd443;
    font-size: 10px;
    padding: 1px 4px;
  }
  &.minPrice {
    background-color: #faf9f6;
    font-size: 10px;
    padding: 4px 4px;
  }
}
