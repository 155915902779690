@import "styles/base";

.feedback {
  position: absolute;
  color: #ffffff;
  top: 70%;
  left: 78%;
  transform: translate(-50%, -50%);
  width: 40vw;
  border: none;
  padding: 50px 60px;
  border-radius: 40px;
  text-align: center;
  background: linear-gradient(to bottom, #0b4356 33.33%, #ffffff 33.33%, #ffffff 33.33%, #ffffff 33.33%);
  font-family: Silka;
  display: block;

  .saveButton {
    background-color: #0b4356;
    color: #ffffff;
    border-radius: 10px;
    border: 1px solid #0b4356;
    &:hover {
      border-color: #0b4356;
      color: #0b4356;
      background-color: #ffffff;
    }
  }

  .addButton {
    background-color: #ffffff;
    color: #0b4356;
    border-radius: 10px;
    border: 1px solid #0b4356;
    &:hover {
      border-color: #ffffff;
      color: #ffffff;
      background-color: #0b4356;
    }
  }
}

.textarea {
  width: 30vw;
  font-family: Silka;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  margin-top: 70px;
  padding: 12px;
  border-radius: 15px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-color: #0b4356;

  &:hover,
  &:focus {
    border-color: #0b4356;
    box-shadow: 0 0 0 1px #0b4356;
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
}

.feedbackIcon {
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: fixed;
  width: 200px;
  height: 150px;
  right: 2%;
  bottom: 5%;
  background-color: #f7cec4;
  border-radius: 50%;
  clip-path: polygon(0% 0%, 100% 0%, 100% 80%, 50% 100%, 0% 80%);
  z-index: 3;

  @media only screen and (max-width: 1024px) {
    position: fixed;
    top: auto;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 60px;
    border-radius: 0;
    clip-path: none;
  }
}

/* Animacja delikatnego dryfu chmurki */
@keyframes drift {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
  }
}
.feedbackIcon {
  animation: drift 4s ease-in-out infinite;

  @media only screen and (max-width: 1024px) {
    animation: none;
  }
}

@include media("<phone") {
  .feedbackIcon {
    display: none;
  }
}
