@import "styles/base";
@import "styles/variables";

.offerReservationShort {
  position: relative;
  background-color: #f4f5f7;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  width: 35%;
  margin-left: 20px;
  padding: 40px 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media only screen and (max-width: map-get($breakpoints, desktop)) {
    margin: 20px 0;
    width: 100%;
  }

  @media only screen and (min-width: map-get($breakpoints, phone)) and (max-width: map-get($breakpoints, desktop)) {
    flex-direction: row;
  }
}

.noTerm {
  margin: auto;
  font-size: larger;
}

.reservationHeader {
  position: absolute;
  left: 0;
  top: 0;
  padding: 10px 20px;
  background-color: $color_secondary;
  color: #fff;
  border-radius: 20px 0 20px 0;
}

.reservationFields {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (min-width: map-get($breakpoints, phone)) and (max-width: map-get($breakpoints, desktop)) {
    width: 50%;
    padding: 0 40px;
  }

  div {
    background-color: #fff;
    margin: 15px 0;
    border: 1px solid #000;
    border-radius: 10px;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.reservationSummary {
  @media only screen and (min-width: map-get($breakpoints, phone)) and (max-width: map-get($breakpoints, desktop)) {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.reservationPrices {
  @media only screen and (min-width: map-get($breakpoints, phone)) and (max-width: map-get($breakpoints, desktop)) {
    width: 70%;
    margin: 10px 0;
  }

  div {
    margin: 10px 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

.personPrice {
  font-weight: bold;
}

.totalPrice {
  font-weight: bold;
}

.reservationButton {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;

  button {
    width: 70%;
  }
}

.maintenances {
  span {
    text-align: center !important;
  }
}
