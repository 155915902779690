@import "styles/base";
.wrapper {
  text-decoration: none;
  color: #000000;
  margin: unset;
}
.cardRealtedOffer {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  background-color: #fff;
  height: 390px;
}
.cardRealtedOffer:hover {
  transform: scale(1.05);
}
.imageWrapper {
  height: 200px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}
.titleWrapper {
  font-family: $font-family-sharp-grotesk;
  font-weight: 500;
  font-size: 22px;
  line-height: 1.2;
  text-overflow: ellipsis;
}
.detailsWrapper {
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.price {
  font-size: 20px;
  font-family: $font-family-sharp-grotesk;
  white-space: nowrap;
  color: #000;
  text-align: right;
  margin-top: 4px;
}
.locationWrapper {
  @include font(12px, 100, 1.5, $font-family-galano-grotesque);
  text-transform: uppercase;
  margin-top: 4px;
  color: #000;
}
