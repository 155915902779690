@import "styles/base";

.list {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.item {
  @include font(10px, 300, 1.1);
  display: flex;
  align-items: center;
  padding: 0;
  font-size: 15px;
  font-weight: 400;
  margin-right: 22px;
  cursor: pointer;
  label.isDisable {
    color: #bdbdbd;
  }
  &:last-child {
    margin-right: 0;
  }

  .checkbox {
    margin: 0 0 0 -10px;
  }

  label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}
