@import "styles/base";
h1 {
  font-family: $font-family-sharp-grotesk-book;
  font-size: 30px;
  font-weight: 300;
}
.offerNav {
  display: flex;
  justify-content: space-between;
  margin: 35px 0;
  .propertyItems {
    display: flex;
  }
  .locationWrapper {
    @include font(12px, 100, 1.5, $font-family-galano-grotesque);
    text-transform: uppercase;
    margin: 4px;
    color: #000;
  }
  .propertyIcon {
    margin-right: 7px;
  }
}

.imageWrapper {
  width: 100%;
  background-color: #ccc;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  margin: 25px 0 30px 0;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 16px;
    max-height: 400px;
  }
}
.buttonBook {
  width: 200px;
}
.locationWrapper {
  margin-bottom: 16px;
}
@include media("<tablet") {
  .propertyItems {
    flex-direction: column;
  }
}
