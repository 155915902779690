$breakpoints: (
  phone: 576px,
  tablet: 768px,
  desktop: 1024px,
  large: 1200px,
);

$font-family-silka: "Silka";
$font-family-sharp-grotesk: "Sharp Grotesk Medium";
$font-family-sharp-grotesk-book: "Sharp Grotesk Book";
$font-family-galano-grotesque: "Galano Grotesque";
$font-test-tiempos-headline: "Test Tiempos Headline";

/* colors */
$color-primary: #ff7427;
$color-secondary: #0b4356;
