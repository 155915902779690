@import "styles/base";

.themeWrapper {
  width: 100%;
  overflow-x: hidden;
  position: relative;
}
.header {
  padding-top: 12px;
}
