.FilterCompose_container {
  button {
    padding: 5px !important;
    border-radius: 5px !important;
    margin: 5px !important;
    border: 1px solid black !important;

    &.active {
      border: 0 !important;
      transform: scale(1.12);
      background-color: #ffbb12;
      &:hover {
        background-color: #ffbb12;
      }
    }
  }
}
.divider {
  background-color: black;
  margin: 5px 0px 20px 0px !important;
}
.propertyIcon {
  margin-right: 8px;
}
