@mixin font($font-size, $font-weight: null, $line-height: null, $font-family: $font-family-silka) {
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: $line-height;
  font-family: $font-family;
}

@mixin breakpoint($point) {
  @if $point == small {
    @media (max-width: 470px) {
      @content;
    }
  }
  @if $point == large {
    @media (max-width: 1000px) {
      @content;
    }
  } @else if $point == medium {
    @media (max-width: 861px) {
      @content;
    }
  }
}
