@import "styles/base";

.popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: #fff;
  border: none;
  padding: 30px 50px;
  border-radius: 40px;
  text-align: center;
  &:focus {
    outline: none;
  }
}
