@import "styles/base";

.wrapperList {
  position: relative;

  &::before {
    content: "";
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    position: absolute;
    top: 0;
    width: 100%;
    height: 10px;
    z-index: 1;
  }
}

.list {
  margin: -10px 0 0;
  padding: 5px 0 10px;
}

.list::-webkit-scrollbar {
  display: none;
}

.item {
  @include font(13px, 300, 1.2);
  display: inline-block;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.01);
  border-radius: 107px;
  min-width: 80px;
  text-align: center;
  color: #000;
  padding: 6px 20px;
  margin: 3px 3px;

  &.active {
    background-color: #0b4356;
    color: #fff;
  }
}

.showMoreLessToggle {
  text-decoration: underline;
  cursor: pointer;
  display: inline-block;
  padding: 10px 15px;
}
.chosen {
  position: relative;
  padding-left: 30px !important;
  border: #858585 2px solid !important ;
  background-color: rgba(212, 245, 255, 0.5) !important;
}

.accommodation {
  background-color: #a0d9d0;
}

.activity {
  background-color: #eff9f0;
}

.boardType {
  background-color: #ddc8c4;
}

.cuisine {
  background-color: #f5e2c8;
}

.hotelAmenities {
  background-color: #a9def9;
}

.hotelAttractions {
  background-color: #fbf7f4;
}

.hotelType {
  background-color: #f4ebbe;
}

.localization {
  background-color: #f9f5e3;
}

.nature {
  background-color: #d7ebba;
}

.offerType {
  background-color: #f7cec4;
}

.other {
  background-color: #e4e4e4;
}

.roomAmenities {
  background-color: #dbbbf5;
}

.roomType {
  background-color: #e8ebf7;
}

.skiing {
  background-color: #b1cddd;
}

.transportation {
  background-color: #aff9c9;
}

.tripAttractions {
  background-color: #cad2c5;
}

.tripType {
  background-color: #dfe2cf;
}

.tickIcon {
  position: absolute;
  top: 3px;
  left: 6px;
}
