@import "styles/base";

.button {
  @include font(12px, 600, 18px, $font-family-silka);
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  background: #ff7427;
  border-radius: 39px;
  color: rgba(5, 43, 53, 1);
  cursor: pointer;
}

.transparent {
  margin-right: 20px;
  padding-top: 10px;
  max-width: 150px;
  height: auto;
  background: none;
  font-family: "Silka";
  font-weight: normal;
  font-size: 18px;
  letter-spacing: 1px;
  text-align: center;
  text-transform: lowercase;
  outline: none;
  cursor: pointer;
  background-color: transparent;
}
