@import "styles/base";

.button {
  @include font(22px, 400, 2px, $font-family-silka);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  width: 20px;
  height: 20px;
  background-color: #ffbb12;
  border-radius: 4px;
}

.count {
  @include font(16px, 400, 1.2, $font-family-silka);
  display: block;
  color: #000;
  min-width: 20px;
  text-align: center;
  flex: none;
}
