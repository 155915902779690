@import "styles/base";
.searchBoxContainer {
  display: flex;
  flex-direction: column;
  max-width: 1100px;
  align-items: center;
  margin: 0 auto;
  @include media("<desktop") {
    flex-direction: column;
  }
}

.classicSearch_container {
  align-self: flex-end;
  justify-content: center;
  position: relative;
  a {
    position: relative;
    top: 5px;
    margin-right: 5px;
  }
  .classicSearch_button {
    text-transform: capitalize !important;
    margin-top: 5px !important;
    width: fit-content;
  }
}
.searchBox {
  display: flex;
  min-width: 100%;
  justify-content: center;
  align-items: flex-start;
  gap: 50px;
  background-color: #fff;
  padding: 30px;
  padding-bottom: 21px;
  border-radius: 23px;
  @include media("<desktop") {
    flex-direction: column;
  }
}
.searchButton {
  width: 214px;
  height: 68px;
  letter-spacing: 1px;
  background: #ff7427;
  border-radius: 60px !important;
  color: rgba(5, 43, 53, 1);
}
