@import "styles/base";

.termContainer {
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
}

.termSelects {
  display: flex;
}
.termSelects input {
  margin-right: 16px;
}

.offerTerms {
  padding: 10px;
  background-color: #f4f5f7;
  border: 1px solid #e0e0e0;
  border-radius: 20px;

  button {
    background-color: #ffd622;
    height: 3em;
    padding: 10px;
    border-radius: 20px;
    border: none;
  }

  button:hover {
    background-color: $color_primary;
    cursor: pointer;
  }

  button:active {
    background-color: #bb531b;
  }
}

.pricePredictionBox {
  display: flex;
  align-items: center;
  text-align: left;
  img {
    margin-right: 10px;
  }
}
