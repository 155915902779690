@import "styles/base";

.card {
  display: flex;
  background: #fff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  font-family: $font-family-silka;
  margin: 0 0 64px 0;
  box-sizing: border-box;
  position: relative;
  @include media("<tablet") {
    flex-direction: column;
  }
}

.title {
  @include font(22px, 500, 1.2, $font-family-sharp-grotesk);
  text-overflow: ellipsis;
  max-height: 100px;
  overflow: hidden;
  margin: 0 0 6px;
  cursor: pointer;
  color: #000;
  display: block;

  &:hover {
    color: $color-primary;
  }
}

.imageWrapper {
  position: relative;
  width: 30%;
  display: flex;
  background-color: #ccc;
  color: #fff;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  min-height: 220px;

  @include media("<tablet") {
    width: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;

    @include media("<tablet") {
      border-top-right-radius: 16px;
      border-bottom-left-radius: 0px;
      width: 100%;
    }
  }

  .score {
    border-radius: 17px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    position: absolute;
    display: flex;
    align-items: center;
    padding: 3px 0px 3px 7px;
    font-size: 12px;
    font-weight: bold;
    top: 10px;
    left: 10px;
    z-index: 5;
  }
}

.containerWrapper {
  width: 70%;

  @include media("<tablet") {
    width: 100%;
  }
}

.locationWrapper {
  @include font(12px, 100, 1.5, $font-family-galano-grotesque);
  text-transform: uppercase;
  margin-top: 4px;
  color: #000;
}

.detailsWrapper {
  display: flex;
  flex-direction: column;
  margin: 23px 24px 7px;
  font-size: 15px;
  text-align: left;
  width: 100%;
}

.pricePredictionIcon {
  margin-top: 24px;
  margin-right: 5px;
}

.priceWrapper {
  margin: 23px 60px 0 0;
  text-align: center;
  max-width: 130px;
  flex: none;
  position: relative;
  .price {
    @include font(24px, 500, 1.2, $font-family-sharp-grotesk);
    white-space: nowrap;
    color: #000;
  }
}

.propertyItems {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  border-top: 1px solid #f4f4f4;
  margin: 4px 24px 14px;
  padding-top: 8px;

  div:last-child {
    margin-right: 0;
  }
}

.propertyLabel {
  max-width: calc(100vw / 2);
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;

  &:first-letter {
    text-transform: uppercase;
  }
}

.propertyIcon {
  margin-right: 4px;
}

.semanticFilters {
  justify-content: space-between;
  padding: 5px 20px;
}
