@import "~@csstools/normalize.css";
@import "base";

body,
html {
  background-color: #fff;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

iframe {
  display: none;
}

body {
  @include font(16px, 300, 1.2, $font-family-silka);
}

.app {
  display: block;
  height: 100%;
  width: 100%;
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

ul {
  margin: 0;
  padding: 0;
}

.slick-prev:before,
.slick-next:before {
  color: #000 !important;
}
