@import "styles/base";

.themeWrapper {
  width: 100%;
  overflow-x: hidden;
}
.decorationImage {
  width: 50%;
}
.decorationImage img {
  margin-top: 70px;
  min-width: 200px;
}
.errorBox {
  display: flex;
  margin: 50px 0 70px 70px;
  justify-content: space-between;
  max-width: 1000px;
}
.errorPageTextBox {
  width: 50%;
  .error {
    font-family: $font-test-tiempos-headline;
    font-weight: bold;
    font-size: 132px;
  }
  .errorPageTitle {
    font-family: $font-family-sharp-grotesk;
    font-weight: 300;
    font-size: 36px;
    margin-bottom: 35px;
  }
  .errorPageSubtitle {
    font-family: $font-family-silka;
    font-weight: 400;
    font-size: 24px;
  }
  .homepageButton {
    height: 68px;
    letter-spacing: 1px;
    background: #ff7427;
    border-radius: 60px !important;
    color: rgba(5, 43, 53, 1);
    margin-top: 60px;
    padding: 0 50px;
  }
}

@include media("<desktop") {
  .decorationImage {
    display: none;
  }
  .errorBox {
    margin-left: 30px;
  }
  .errorPageTextBox {
    width: 100%;
    .error {
      font-size: 90px;
    }
    .errorPageSubtitle {
      font-size: 24px;
      margin-bottom: 15px;
    }
    .errorPageSubtitle {
      font-size: 18px;
    }
  }
}
