@import "~@csstools/normalize.css";
@import "include-media/dist/_include-media";
@import "variables";
@import "mixins";
@import "fonts";

.wrapper {
  margin: auto;
  position: relative;
  box-sizing: border-box;
}

input {
  width: 100%;
  height: 40px;
  border: 1px solid #ccc;
  color: #888;
  text-align: left;
  border-radius: 4px;
  line-height: 1.5;
  font-weight: 400;
  margin-bottom: 16px;
}

.semanticFiltersBox:empty {
  display: none;
}
