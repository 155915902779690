@import "styles/base";

.description {
  line-height: 28px;
}

.offerDetails {
  font-family: $font-family-silka;
  padding-bottom: 16px;
  margin-top: 50px;
}
.offerDetailsHeader {
  padding-top: 64px;
}
.offerDetailsTitle {
  display: flex;
  align-items: center;
  padding-top: 36px;
}

.offerDetailsTitle img {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

.offerDetailsTitle h3 {
  font-family: $font-family-sharp-grotesk-book;
  font-size: 30px;
  font-weight: 300;
}

.offerDetailsDescription {
  word-break: break-word;
  margin-bottom: 36px;
}
.offerImages {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  justify-content: center;

  @include breakpoint(small) {
    flex-direction: column;
  }
}
.offerImages img {
  width: auto;
  height: 400px;
  padding-top: 32px;
  padding-left: 8px;
  padding-right: 8px;
  @include breakpoint(small) {
    width: 100%;
  }
}

.description ul,
li {
  list-style: circle;
  padding-left: 2px;
  line-height: 23px;
  font-size: 18px;
  font-family: $font-family-silka;
}
.offerDetailsDescription div > p {
  display: flex;
  flex-direction: column;
  font-family: $font-family-silka;
  line-height: 23px;
  font-size: 18px;
}

.propertyItems {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  border-top: 1px solid #f4f4f4;
  margin: 4px 24px 14px;
  padding-top: 8px;

  div:last-child {
    margin-right: 0;
  }
}

.propertyLabel {
  max-width: calc(100vw / 2);
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;

  &:first-letter {
    text-transform: uppercase;
  }
}

.propertyIcon {
  margin-right: 4px;
}

.semanticFilters {
  justify-content: space-between;
  padding: 20px;
}

.buttonBook {
  height: 50px;
  width: 200px;
}

.popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: #fff;
  border: none;
  padding: 30px 50px;
  border-radius: 40px;
  text-align: center;
  &:focus {
    outline: none;
  }
}
.opinions {
  margin-top: 50px;
}

.opinion {
  padding: 20px;
  background-color: #f4f5f7;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
}

.opinionBox {
  display: flex;
  align-items: center;
}

.opinionTitle {
  margin-right: 20px;
}

.opinionParagraph {
  margin-left: 23px;
}

.positiveOpinionCircle {
  border-radius: 100%;
  border: 2px solid green;
  width: 40px;
  height: 40px;
  line-height: 36px;
  text-align: center;
  font-weight: bold;
  color: green;
}

.negativeOpinionCircle {
  border-radius: 100%;
  border: 2px solid red;
  width: 40px;
  height: 40px;
  line-height: 36px;
  text-align: center;
  font-weight: bold;
  color: red;
}

.ratingTitle {
  font-size: 12px;
  margin: 0 35px 0 8px;
}

.highlight {
  background-color: blanchedalmond;
}

.informationBox {
  border-radius: 24px;
  background-color: rgba(201, 196, 196, 0.211);
  padding: 24px;
  margin-top: 40px;
}
